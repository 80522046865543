<template>
  <div class="row" v-loading="true" id="loading" lock="true">
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Loading} from 'element-ui'

  Vue.use(Loading.directive)
  export default {}
</script>
<style>
  #loading {
    min-height: 200px;
    display: flex;
    align-items: center;
  }

  .el-loading-spinner .path {
    stroke: #66615B !important;
  }

  .el-loading-mask {
    background: rgba(0, 0, 0, 0.7) !important;
  }
  .el-loading-spinner .el-loading-text{
    color: #fff;
    margin: 3px 0;
    font-size: 18px;
  }
  .el-loading-spinner i {
    color: #fff;
    font-size: 30px;
  }
</style>
